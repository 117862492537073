<template>
  <QueryFilterObserver v-bind="filters.search" />
</template>

<script>
import { mapGetters } from 'vuex';

import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  computed: {
    ...mapGetters('reference', ['ownersForSelect']),
    filters() {
      return {
        search: {
          component: 'v-text-field',
          name: 'search',
          attrs: {
            dense: true,
            'hide-details': true,
            'append-icon': 'mdi-magnify',
            class: 'search-field',
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 400px;
  font-size: 12px;
}
</style>
