<template>
  <v-text-field class="field" :value="factor" :rules="[rules.required, rules.positive]" @focus="onFocus" @keyup.enter="submit" @keyup.esc="onDismiss" @blur="onBLur" dense outlined hide-details />
</template>

<script>

export default {
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value,
        positive: (value) => value > 0,
        number: (value) => /^\d+$/.test(value) || /^[+-]?\d+(\.\d+)?$/.test(value),
      },
    };
  },
  computed: {
    factor() {
      return this.product.unit_factor ? parseFloat(this.product.unit_factor).toFixed(3) : 1;
    },
  },
  methods: {
    validate(value) {
      return this.rules.required(value) && this.rules.positive(value) && this.rules.number(value) && (parseFloat(value).toFixed(3) !== this.factor);
    },
    async submit(event) {
      const { value } = event.target;
      if (!this.validate(value)) return;
      try {
        const affected = await this.$store.dispatch('inventory_items/SET_PRODUCT_UNIT_FACTOR', { itemId: this.product.id, unitFactor: value });
        this.$emit('affected', affected[0]);
        this.$notify.showMessage({ message: 'Сохранено', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      }
    },
    onFocus(event) {
      event.target.select();
      this.$emit('focus');
    },
    onBLur(event) {
      this.submit(event);
      this.$emit('blur');
    },
    onDismiss() {
      this.$emit('blur');
    },
  },
};
</script>

<style scoped>
.field {
  font-size: 12px;
  max-width: 56px;
  background-color: hsl(0, 100%, 100%);
}
</style>
