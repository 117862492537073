<template>
  <div class="d-flex justify-center mt-12">
    <v-img max-width="280px" max-height="560px" :src="product.image_url" v-if="product.image_url" />
    <v-img max-width="280px" max-height="560px" src="@/assets/nopicture.svg" v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('inventory_items', ['product']),
  },
};
</script>
