<template>
  <v-btn class="text-caption text-capitalize" depressed color="primary" @click="$router.push(`/products/${product.gmid}`)" v-text="'Редактировать характеристики'" v-if="product.gmid" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('inventory_items', ['product']),
  },
};
</script>
