<template>
  <div class="product-card">
    <div class="pl-6 pt-4 caption">
      Следующий товар:
    </div>
    <v-simple-table class="ma-2" width="100%">
      <template v-slot:default>
        <tbody>
          <WarehouseItemsRow :item="item" :index="index" :index-for-human="indexForHuman" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WarehouseItemsRow from '@/components/WareHouseItems/WarehouseItemsRow';

export default {
  components: {
    WarehouseItemsRow,
  },
  computed: {
    ...mapGetters('warehouses', ['getSelectedItemIndex', 'getNextWarehouseItem']),
    index() {
      return this.getSelectedItemIndex + 1;
    },
    item() {
      return this.getNextWarehouseItem;
    },
    indexForHuman() {
      return this.index + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  color: hsla(0, 0%, 0%, 0.24);
  background-color: hsla(0, 0%, 98%, 1);
  border-radius: 6px;
  border: 1px solid hsla(0, 0%, 0%, 0.04);
  box-shadow: 0px 2px 6px 0px hsla(0, 0%, 0%, 0.08);
}

</style>
