export const clearEmptyValues = (data, emptyValues = [null, undefined, '']) => {
  /**
   * Removes extra values from {Object} or {Array}
   */
  if (Array.isArray(data)) {
    return data.filter((item) => !emptyValues.includes(item));
  }

  if (typeof data === 'object') {
    const normalizedObject = {};

    Object
      .entries(data)
      .filter(((item) => !emptyValues.includes(item[1])))
      .forEach(([key, value]) => {
        normalizedObject[key] = value;
      });

    return normalizedObject;
  }

  return data;
};
