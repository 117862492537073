import { normalizeValue } from '@/helpers/filters';

export const getAttributesWithValues = (attributes, attributeValues) => {
  const list = [];
  attributes
    .forEach((attribute) => {
      list.push({ attribute, value: attributeValues.filter((a) => a.attribute.id === attribute.id).map((item) => normalizeValue(item.value)) });
    });
  return list;
};
