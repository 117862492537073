<template>
  <v-container fluid>
    <template v-if="!hasError">
      <v-col class="my-15 text-center" cols="12" v-if="isLoading">
        <v-progress-circular size="32" indeterminate />
      </v-col>
      <template v-else>
        <v-col cols="12" class="sticky d-flex justify-space-between align-center">
          <div class="oveflow-hidden align-center">
            <div class="d-flex align-baseline">
              <div class="caption text--secondary" v-text="ownerName" />
              <div v-html="`/ ${productName}`" class="oveflow-hidden font-weight-medium ml-2 text-h6" />
            </div>
            <v-tabs class="tabs caption">
              <v-tab v-for="tab in tabs" :key="tab.ref" v-text="tab.label" @click="scrollTo($refs[tab.ref])" class="caption text-capitalize" />
            </v-tabs>
          </div>
          <v-spacer />
          <AddToFeedButton />
        </v-col>
        <v-col cols="12">
          <ProductCard ref="card" />
        </v-col>
        <v-col cols="12">
          <BindedProducts ref="binded" @show="(val) => show.binded = val" />
        </v-col>
        <v-col cols="12">
          <CanonicalProducts ref="canonical" @show="(val) => show.canonical = val" />
        </v-col>
        <v-col class="d-flex justify-center align-center my-6" cols="12" v-if="!show.products && renderOffersGroups">
          <v-btn class="text-caption text-capitalize" depressed color="primary" @click="show.products = true" v-text="'Далее: фиды поставщиков'" />
        </v-col>
        <v-col cols="12" v-show="show.products">
          <OffersGroups v-if="renderOffersGroups" />
        </v-col>
        <v-col class="mb-16 mt-6" cols="12" v-if="!product.gmid">
          <PlaceProductToInventory />
        </v-col>
        <v-col class="mb-16 mt-6" cols="12" v-if="getNextWarehouseItem">
          <NextWareHouseItem />
        </v-col>
        <v-col class="12">
          <h2 class="mb-5">Редактирование</h2>
          <WareHouseItemForm ref="edit" @show="(val) => show.edit = val" :product="product" />
        </v-col>
      </template>
    </template>
    <template v-else>
      <v-col cols="12">
        <h1>404</h1>
      </v-col>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductCard from '@/components/ProductCard/ProductCard';
import BindedProducts from '@/components/BindedProducts/BindedProducts';
import OffersGroups from '@/components/Offers/OffersGroups';
import CanonicalProducts from '@/components/CanonicalProducts/CanonicalProducts';
import PlaceProductToInventory from '@/components/PlaceProduct/PlaceProductToInventory';
import NextWareHouseItem from '@/components/Item/NextWareHouseItem';
import AddToFeedButton from '@/components/Feed/AddToFeedButton';
import WareHouseItemForm from '@/components/Item/WareHouseItemForm';

const GOTO_OPTIONS = {
  duration: 300,
  offset: 140,
  easing: 'easeInOutCubic',
};

export default {
  components: {
    ProductCard,
    BindedProducts,
    OffersGroups,
    CanonicalProducts,
    PlaceProductToInventory,
    NextWareHouseItem,
    AddToFeedButton,
    WareHouseItemForm,
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      show: {
        binded: false,
        canonical: false,
        products: false,
      },
    };
  },
  metaInfo() {
    const productName = this.product?.name ?? '';
    return { title: `${productName} — Инвентарь` };
  },
  computed: {
    ...mapState('inventory_items', ['product']),
    ...mapGetters('warehouses', ['getNextWarehouseItem']),
    ...mapGetters('inventory_items', ['renderOffersGroups']),
    tabs() {
      const tabsList = [
        {
          label: 'Характеристики',
          ref: 'card',
          show: true,
        },
        {
          label: 'Установленные связи',
          ref: 'binded',
          show: this.show.binded,
        },
        {
          label: 'Найти связи',
          ref: 'canonical',
          show: true,
        },
        {
          label: 'Редактирование',
          ref: 'edit',
          show: true,
        },
      ];
      return tabsList.filter((t) => t.show);
    },
    productId() {
      return this.$route.params.productId;
    },
    productName() {
      return this.product.name.replace(/ /g, '<wbr> ');
    },
    ownerName() {
      return this.product.owner?.name;
    },
  },
  watch: {
    productId: {
      immediate: true,
      async handler(productId) {
        this.isLoading = true;
        this.hasError = false;
        this.show.products = false;

        try {
          await Promise.all([
            this.FETCH_PRODUCT(productId),
            this.FETCH_LISTINGS(),
            this.FETCH_WAREHOUSES(),
          ]);
        } catch (e) {
          this.hasError = true;
          this.$notify.showErrorMessage(e.response);
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('inventory_items', ['FETCH_PRODUCT']),
    ...mapActions('feeds', ['FETCH_LISTINGS']),
    ...mapActions('reference', ['FETCH_WAREHOUSES']),
    scrollTo(target) {
      this.$vuetify.goTo(target, GOTO_OPTIONS);
    },
  },
};
</script>

<style lang="css" scoped>
.sticky {
  position: sticky;
  top: 0;
  background-color: hsl(0, 0%, 100%);
  z-index: 10;
  padding-bottom: 0px;
  border-bottom: solid 1px;
  border-bottom-color: hsla(0, 0%, 92%, 1);
}

.oveflow-hidden {
  white-space: nowrap;
  padding-right: 12px;
}
</style>
