<template>
  <v-row no-gutters>
    <v-col class="sticky" cols="12">
      <v-toolbar class="my-2" elevation="0" dense>
        <v-toolbar-title>
          <div v-html="highlight(title, titleHightlight)" />
          <div class="caption text--secondary" v-text="'Подходят ли эти товары из инвентаря для установки связи?'" />
        </v-toolbar-title>

        <v-spacer />

        <CanonicalProductsFilters />
      </v-toolbar>
    </v-col>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>
    <v-col class="my-15 text-center" cols="12" v-else-if="!products.length">
      <span>Здесь ничего нет</span>
    </v-col>
    <v-col cols="12" v-else>
      <CanonicalProductsTable :products="products" />
    </v-col>
  </v-row>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

import hitHighlighter from '@/helpers/hitHighlighter';

import CanonicalProductsFilters from '@/components/CanonicalProducts/CanonicalProductsFilters';
import CanonicalProductsTable from '@/components/CanonicalProducts/CanonicalProductsTable';

export default {
  components: {
    CanonicalProductsFilters,
    CanonicalProductsTable,
  },
  data() {
    return {
      isLoading: false,
      title: 'Найти связи: инвентарь',
      titleHightlight: 'инвентарь',
    };
  },
  computed: {
    ...mapState('inventory_items', ['product']),
    ...mapState('canonical_products', ['products']),
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler: debounce(function (val, oldVal) {
        if (!oldVal && !this.$route.query.search) {
          return this.$router.replace({
            query: {
              ...this.$route.query,
              search: this.product.name,
            },
          });
        }

        this.fetchProducts();
      }, 400),
    },
    products: {
      immediate: true,
      handler(items) {
        this.$emit('show', items.length);
      },
    },
  },
  methods: {
    ...mapActions('canonical_products', ['FETCH_PRODUCTS']),
    async fetchProducts() {
      this.isLoading = true;
      const query = { ...this.$route.query };

      if (this.pageCanonical === 1) {
        delete query.page;
      } else {
        query.page = this.pageCanonical;
      }

      try {
        await this.FETCH_PRODUCTS({
          ...query,
          page_size: 10,
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = e.isAxiosCancelError;
        if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
      }
    },
    highlight(input = '', sample = '') {
      return hitHighlighter(input, sample);
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 93px;
  background-color: hsl(0, 0%, 100%);
  z-index: 5;
  border-bottom: solid 1px;
  border-bottom-color: hsla(0, 0%, 92%, 1);
  padding-bottom: 16px;
}
</style>
