<template>
  <span v-html="value" />
</template>

<script>
export default {
  name: 'ProductParamValue',
  props: {
    value: { type: String, default: '–' },
  },
};
</script>
