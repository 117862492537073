<template>
  <tr class="products-list__row" :class="{'products-list__row--disabled': isLoading === item.id }">
    <td class="text-left text-caption" v-text="index + 1" />
    <td class="text-left text-caption">
      <router-link class="products-list__row--sku" :to="buildProductUrl(item)" v-text="item.id" />
    </td>
    <td class="text-left text-caption" v-html="highlight(item.name, searchText)" @mouseenter="(event) => showPopover(event, item)" @mouseleave="hidePopover" />
    <td class="text-left text-caption" v-html="item.category_name" />
    <td class="text-left text-caption" v-html="item.vendor_name" />
    <td class="text-left text-caption text-no-wrap" v-html="getPrices(item)" />
    <td class="text-center">
      <v-btn depressed small text width="71px" color="primary" @click="bind(item.id)" v-if="showBindWithItem(item) && !gmid" class="text-left products-list__row--bind text-capitalize">
        Связать
      </v-btn>
      <v-btn depressed small text width="71px" disabled v-else-if="showUnbindFromItem(item)">
        <v-icon> mdi-check </v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import popover from '@/helpers/popover';
import hitHighlighter from '@/helpers/hitHighlighter';
import { withCurrencySymbol, normalizePrice, buildProductUrl } from '@/helpers/formatters';

export default {
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: null,
    };
  },
  computed: {
    ...mapState('inventory_items', ['product']),
    ...mapGetters('reference', ['getWarehouseRegionId']),
    searchText() {
      return this.$route.query.search;
    },
    gmid() {
      return parseInt(this.product.gmid, 10);
    },
    productRegionId() {
      if (this.product.warehouse_items.length > 0) {
        const warehousesID = this.product.warehouse_items[0].warehouse_id;
        return this.getWarehouseRegionId(warehousesID);
      }
      return null;
    },
  },
  methods: {
    ...mapActions('inventory_items', ['BIND_PRODUCT_TO_CANONICAL']),
    async bind(id) {
      try {
        this.isLoading = id;
        const affected = await this.BIND_PRODUCT_TO_CANONICAL({ itemId: this.product.id, productId: id });
        this.$notify.showMessage({ message: `Изменено элеменов: ${affected.length}`, color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = null;
      }
    },
    highlight(input, sample) {
      return hitHighlighter(input || '', sample || '');
    },
    showBindWithItem(item) {
      return this.gmid !== item.id;
    },
    showUnbindFromItem(item) {
      return this.gmid === item.id;
    },
    getPrices(item) {
      const stats = item.stats.find((s) => s.region.id === this.productRegionId);

      if (!stats) return '';

      const minPrice = stats.min_price ? normalizePrice(parseFloat(stats.min_price)) : '';
      const maxPrice = stats.max_price ? normalizePrice(parseFloat(stats.max_price)) : '';
      const totalPrice = minPrice === maxPrice ? minPrice : `${minPrice}–${maxPrice}`;

      return withCurrencySymbol(totalPrice, stats.currency);
    },
    buildProductUrl(item) {
      if (item) return buildProductUrl(item, 'id');
      return '';
    },
    showPopover(event, item) {
      popover.show(event, item, 'image');
    },
    hidePopover() {
      popover.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.products-list {
  &__row {
    transition: all 0.1 ease-in-out;
    background-color: hsla(0, 0%, 98%, 1);

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &--sku {
      text-decoration: underline;
      cursor: pointer;
      color: hsl(0, 0%, 0%);

      &:hover {
        color: var(--v-primary-base);
      }
    }

    &--bind {
      border-radius: 6px;
      background-color: rgba(28, 78, 255, 0.08);
      font-weight: 400;
    }

  }
}
</style>
