<template>
  <v-row no-gutters v-if="showComponent">
    <v-col cols="12 sticky">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <div v-html="title" class="font-weight-medium" />
          <div class="caption text--secondary" v-text="'Установлена связь со следующими товарами:'" />
        </v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>
    <v-col cols="12" v-else>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="products-list__row">
              <th class="text-left" width="2%">№</th>
              <th class="text-left" width="5%">Артикул</th>
              <th class="text-left" width="auto">Название товара</th>
              <th class="text-left" width="7%">Источник</th>
              <th class="text-left" width="5%">Категория</th>
              <th class="text-left" width="10%">Бренд</th>
              <th class="text-left" width="auto">Цена</th>
              <th class="text-left" width="auto">Цена/{{ canonicalUnit }} <PricePerUnitFactorTooltip /></th>
              <th class="text-center" width="5%">Связь</th>
            </tr>
          </thead>
          <tbody>
            <tr class="products-list__row" :class="{'products-list__row--disabled': isLoading === item.id }" v-for="(item, index) in inventoryItems" :key="item.id" @mouseenter="shownRatioInput = item.id" @mouseleave="shownRatioInput = false">
              <td class="text-left text-caption" v-text="index + 1" />
              <td class="text-left text-caption">
                <Article :item="item" />
              </td>
              <td class="text-left text-caption" @mouseenter="(event) => showPopover(event, item)" @mouseleave="hidePopover">
                <v-icon v-if="itemIsCanonical(item)" size="13px" class="products-list__row--canonical">
                  mdi-star-circle
                </v-icon>
                <span v-text="item.name" />
              </td>
              <td class="text-left text-caption" v-html="ownerName(item)" />
              <td class="text-left text-caption" v-html="categoryName(item)" />
              <td class="text-left text-caption" v-html="vendorName(item)" />
              <td>
                <PriceWithUnitFactor :item="item" :over="shownRatioInput == item.id" @update="update" />
              </td>
              <td class="text-left text-caption text-no-wrap" v-html="getPricesPerUnitFactor(item)" />
              <td class="text-center">
                <v-btn depressed small text width="71px" color="gray" @click="bind(item.id, 'UNBIND_PRODUCT')" class="text-left offers-list__row--unbind text-capitalize">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { formatPrice, buildItemUrl } from '@/helpers/formatters';
import PricePerUnitFactorTooltip from '@/components/Item/PricePerUnitFactorTooltip';
import PriceWithUnitFactor from '@/components/common/Product/PriceWithUnitFactor';
import Article from '@/components/common/Product/Article';
import popover from '@/helpers/popover';

export default {
  components: {
    PricePerUnitFactorTooltip,
    PriceWithUnitFactor,
    Article,
  },
  data() {
    return {
      isLoading: null,
      title: 'Связи',
      itemsPerPage: 10,
      shownRatioInput: false,
    };
  },
  computed: {
    ...mapState('inventory_items', ['product']),
    ...mapState('canonical_products', {
      canonicalProduct: (state) => state.product,
    }),
    canonicalUnit() {
      return this.canonicalProduct.unit_localized;
    },
    gmid() {
      return this.product.gmid;
    },
    inventoryItems() {
      if (!this.canonicalProduct) return [];

      return this.canonicalProduct.inventory_items.filter((item) => item.id !== this.product.id);
    },
    showComponent() {
      return this.gmid && this.inventoryItems.length;
    },
  },
  watch: {
    gmid: {
      immediate: true,
      handler(gmid) {
        if (gmid) this.FETCH_PRODUCT(gmid);
      },
    },
    inventoryItems: {
      immediate: true,
      handler(items) {
        if (items.length > 0 && this.gmid) this.$emit('show', true);
        else this.$emit('show', false);
      },
    },
  },
  methods: {
    ...mapActions('canonical_products', ['FETCH_PRODUCT']),
    ...mapActions('inventory_items', ['UNBIND_PRODUCT']),
    async bind(id, action = 'UNBIND_PRODUCT') {
      try {
        this.isLoading = id;
        const affected = await this[action]({ bindToId: id, bindId: this.product.id });
        this.$notify.showMessage({ message: `Изменено элеменов: ${affected.length}`, color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = null;
      }
    },
    itemIsCanonical(item) {
      return item.sku === this.gmid;
    },
    ownerName(item) {
      return item.owner?.name ?? '—';
    },
    categoryName(item) {
      return item.category?.name ?? '—';
    },
    vendorName(item) {
      return item.vendor?.name ?? '—';
    },
    buildItemUrl(item) {
      if (item) return buildItemUrl(item);
      return '';
    },
    showPopover(event, item) {
      popover.show(event, item, 'image_url');
    },
    hidePopover() {
      popover.hide();
    },
    update() {
      this.FETCH_PRODUCT(this.gmid);
    },
    getPricesPerUnitFactor(item) {
      return formatPrice(item.warehouse_items, 'price_per_unit_factor');
    },
  },
};
</script>

<style lang="scss" scoped>
.products-list {
  &__row {
    transition: all 0.1 ease-in-out;
    background-color: hsla(0, 0%, 98%, 1);

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &--sku {
      text-decoration: underline;
      cursor: pointer;
      color: hsl(0, 0%, 0%);

      &:hover {
        color: var(--v-primary-base);
      }
    }

    &--bind {
      border-radius: 6px;
      background-color: hsla(227, 36%, 5%, 0.08);
      font-weight: 400;
    }

    &--canonical {
      margin-left: -20px;
      margin-right: 8px;
      color: hsl(0, 0%, 0%);
    }

    &--factor {
      width: 56px;
      text-align: center;
    }
  }
}

.sticky {
  position: sticky;
  top: 109px;
  background-color: hsl(0, 0%, 100%);
  z-index: 5;
  border-bottom: solid 1px;
  border-bottom-color: hsla(0, 0%, 92%, 1);
  padding-bottom: 16px;
}
</style>
