<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr class="products-list__row">
          <th class="text-left" width="2%">№</th>
          <th class="text-left" width="5%">Артикул</th>
          <th class="text-left" width="auto">Название товара</th>
          <th class="text-left" width="12%">Категория</th>
          <th class="text-left" width="10%">Бренд</th>
          <th class="text-left" width="auto">Цена</th>
          <th class="text-center" width="5%">Связь</th>
        </tr>
      </thead>
      <tbody>
        <CanonicalProductsRow :item="item" :index="index" v-for="(item, index) in products" :key="item.id" />
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import CanonicalProductsRow from '@/components/CanonicalProducts/CanonicalProductsRow';

export default {
  components: {
    CanonicalProductsRow,
  },
  props: {
    products: { type: Array, required: true },
  },
};
</script>
