<template>
  <div class="d-flex justify-end align-center">
    <QueryFilterObserver v-bind="filters.marketplace" />
    <QueryFilterObserver v-bind="filters.search" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  computed: {
    ...mapState('feeds', ['marketplaces']),
    _marketplaces() {
      return this.marketplaces.map((item) => ({ text: item.name, value: item.slug }));
    },
    filters() {
      return {
        marketplace: {
          component: 'v-select',
          name: 'marketplace_slug',
          attrs: {
            label: 'Маркетплейс',
            items: this._marketplaces,
            dense: true,
            clearable: true,
            hideDetails: true,
            class: ['select-field', 'mr-4'],
          },
        },
        search: {
          component: 'v-text-field',
          name: 'search',
          attrs: {
            dense: true,
            outlined: true,
            hideDetails: true,
            'append-icon': 'mdi-magnify',
            class: 'search-field',
          },
        },
      };
    },
  },
  created() {
    this.FETCH_MARKETPLACES();
  },
  methods: {
    ...mapActions('feeds', ['FETCH_MARKETPLACES']),
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  width: 250px;
  font-size: 12px;
}

.select-field {
  width: 200px;
  font-size: 12px;
}
</style>
