<template>
  <v-menu class="mt-8" v-model="menu" :close-on-content-click="false" left offset-y open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="button text-capitalize text-caption" :class="menu ? 'btn-hover' : 'btn'" depressed text v-bind="attrs" v-on="on">
        {{ title }}

        <v-icon
          right
          dark
        >
          {{ menu ? 'mdi-menu-up' : 'mdi-menu-down' }}
        </v-icon>
      </v-btn>
    </template>
    <AddToFeedList />
  </v-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AddToFeedList from '@/components/Feed/AddToFeedList';

export default {
  components: {
    AddToFeedList,
  },
  data() {
    return {
      isLoading: false,
      menu: false,
    };
  },
  computed: {
    ...mapState('feeds', ['listings']),
    ...mapGetters('inventory_items', ['selectedListings']),
    title() {
      const count = this.selectedListings.length ? ` (${this.selectedListings.length})` : '';
      return `Листинг${count}`;
    },
  },
};
</script>

<style scoped>

.btn {
  border-radius: 6px;
  background-color: hsla(227, 100%, 55%, 0.08);
  color: var(--v-primary-base);
  font-weight: 400;
}

.btn-hover {
  border-radius: 6px;
  background-color: hsla(0, 0%, 85%, 1);
  color: hsl(0, 0%, 0%);
  font-weight: 400;
}
</style>
