<template>
  <v-row no-gutters>
    <v-col class="d-flex align-center" cols="4">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <div class="font-weight-medium" v-html="title" />
          <div class="caption text--secondary" v-text="'Только если вы уверены, что этот товар не с чем связать.'" />
        </v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col class="d-flex justify-center align-center my-6" cols="4">
      <v-btn depressed text color="primary" @click="bind()" class="caption text-left bind-btn text-capitalize">
        Создать эталонный товар
      </v-btn>
    </v-col>
    <v-col cols="auto" />
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      title: 'Создать эталон',
    };
  },
  computed: {
    ...mapState('inventory_items', ['product']),
  },
  methods: {
    ...mapActions('inventory_items', [
      'BIND_PRODUCT',
      'FETCH_PRODUCT',
    ]),
    async bind() {
      try {
        this.isLoading = true;
        await this.BIND_PRODUCT({ bindId: this.product.id, bindToId: 'new' });
        await this.FETCH_PRODUCT(this.product.id);
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bind-btn {
  border-radius: 6px;
  background-color: rgba(28, 78, 255, 0.08);
  font-weight: 400;
}
</style>
