<template>
  <v-row class="text-caption align-center">
    <v-col cols="6" class="text--secondary d-flex justify-space-between align-center">
      {{ title }}/{{ canonicalUnitLocalized }}:
      <PricePerUnitFactorTooltip />
    </v-col>
    <v-col cols="6" v-html="getPricesPerUnitFactor" />
  </v-row>
</template>

<script>
import { formatPrice } from '@/helpers/formatters';

import PricePerUnitFactorTooltip from '@/components/Item/PricePerUnitFactorTooltip';

export default {
  components: {
    PricePerUnitFactorTooltip,
  },
  props: {
    product: { type: Object, required: true },
    priceField: { type: String, require: false, default: 'price_per_unit_factor' },
    title: { type: String, require: false, default: 'Цена' },
  },
  computed: {
    canonicalUnit() {
      return this.$store.state.canonical_products.product?.unit ?? '';
    },
    canonicalUnitLocalized() {
      return this.$store.state.canonical_products.product?.unit_localized ?? '';
    },
    getPricesPerUnitFactor() {
      return formatPrice(this.product.warehouse_items, this.priceField) || '–';
    },
  },
};
</script>
