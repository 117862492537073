<template>
  <v-form ref="form" v-model="isValid" lazy-validation>
    <v-row>
      <v-col class="mt-3" cols="3">
        <v-select class="mb-4" v-model="warehouseItem" :rules="requiredField" label="Склад" :items="warehouses"
                  item-text="warehouseName" return-object outlined dense hide-details
        />
        <v-text-field v-model="price" :label="priceLabel" suffix="" outlined dense />
        <v-text-field v-model="retail" :label="retailLabel" suffix="" outlined dense />
        <v-text-field v-model="stock" label="Остатки" suffix="" outlined dense />
      </v-col>
      <v-col cols="12">
        <v-btn class="mr-4" color="success" @click="submit" :loading="isLoading" elevation="0">Сохранить</v-btn>
        <v-btn color="prirmary" @click="prefillForm" elevation="0" depressed>Сбросить форму</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import get from 'lodash.get';
import { mapActions, mapGetters } from 'vuex';

import { clearEmptyValues } from '@/helpers/clearData';

export default {
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return {
      isValid: true,
      isLoading: false,
      title: 'Редактировать',
      requiredField: [(v) => !!v || 'Обязательно поле'],
      warehouseItem: null,
      price: null,
      retail: null,
      stock: null,
    };
  },
  computed: {
    ...mapGetters('reference', ['getWarehouseRegionName']),
    ...mapGetters('warehouses', ['getSelectedItemIndex']),

    warehouses() {
      return this.product.warehouse_items.map((w) => ({ ...w, warehouseName: this.getWarehouseRegionName(w.warehouse_id) }));
    },

    mapDataFieldsToWarehouseItem() {
      return {
        price: 'price',
        retail: 'retail_price',
        stock: 'stock',
      };
    },

    priceLabel() {
      return `Цена/${this.product.unit_localized}`;
    },

    retailLabel() {
      return `РРЦ/${this.product.unit_localized}`;
    },
  },
  watch: {
    warehouseItem: {
      immediate: true,
      deep: true,
      handler() {
        this.prefillForm();
        this.$nextTick(() => this.$refs.form.validate());
      },
    },
  },
  methods: {
    ...mapActions('warehouses', ['UPDATE_ITEM']),
    formDataFromParams() {
      const data = clearEmptyValues({
        price: this.price,
        retail_price: this.retail,
        stock: this.stock,
      });

      return data;
    },
    async submit() {
      this.isLoading = true;

      try {
        await this.UPDATE_ITEM({
          itemId: this.warehouseItem.id,
          params: this.formDataFromParams(),
        });
        this.$notify.showMessage({ message: 'Информация о продукте обновлена', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    prefillForm() {
      Object
        .entries(this.mapDataFieldsToWarehouseItem)
        .forEach(([dataField, warehouseItemField]) => {
          this[dataField] = get(this.warehouseItem, warehouseItemField);
        });
    },
  },
};
</script>
