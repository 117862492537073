const normalizeString = (val) => val.trim();

/**
 * hitHighlighter
 *
 * @param {String} input string to highlight
 * @param {String} sample string to search matches
 * @returns {String} input string but with wrapped matches
 */
export default function hitHightlighter(input, sample) {
  const sampleArr = normalizeString(sample)
    .toLowerCase()
    .replace(/[^a-zA-ZА-Яа-я0-9\s]/g, '')
    .split(/\s+/);

  return normalizeString(input)
    .split(/\s+/)
    .map((word) => {
      if (sampleArr.indexOf(word.toLowerCase().replace(/[^a-zA-ZА-Яа-я0-9\s]/, '')) !== -1) return `<b>${word}</b>`;
      return word;
    })
    .join(' ');
}
