<template>
  <v-list>
    <v-list-item :ripple="false" v-for="item in listingsItems" :key="item.id" link @click="switchListing(item)">
      <v-list-item-action>
        <v-checkbox color="black" dense :input-value="doesTheProductInListing(item.id) || false" />
      </v-list-item-action>
      <v-list-item-title class="text-caption" v-text="item.name" />
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('inventory_items', ['product']),
    ...mapState('feeds', ['listings']),
    ...mapGetters('inventory_items', ['doesTheProductInListing']),
    listingsItems() {
      return this.listings.map((l) => ({
        id: l.id,
        name: `${l.marketplace.name} (${l.region.name})`,
      })) ?? [];
    },
  },
  methods: {
    ...mapActions('inventory_items', [
      'ADD_TO_LISTING',
      'REMOVE_FROM_LISTING',
    ]),
    async switchListing(listing) {
      try {
        this.isLoading = true;
        const action = this.doesTheProductInListing(listing.id) ? this.REMOVE_FROM_LISTING : this.ADD_TO_LISTING;
        await action({ itemId: this.product.id, listingId: listing.id });
        const message = this.doesTheProductInListing(listing.id) ? `Товар "${this.product.name}" добавлен в листинг ${listing.name}` : `Товар "${this.product.name}" удалён из листинга ${listing.name}`;
        this.$notify.showMessage({ message, color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
