<template>
  <v-row>
    <v-col cols="4">
      <ProductImage />
    </v-col>
    <v-col cols="8">
      <ProductParams />
    </v-col>
    <v-col class="d-flex justify-center align-center" cols="12">
      <GoToEdit />
    </v-col>
  </v-row>
</template>

<script>
import ProductParams from '@/components/ProductCard/ProductParams';
import ProductImage from '@/components/ProductCard/ProductImage';
import GoToEdit from '@/components/ProductCard/GoToEdit';

export default {
  components: {
    ProductParams,
    ProductImage,
    GoToEdit,
  },
};
</script>
