<template>
  <div class="warehouses-items">
    <template v-for="(w, index) in warehouses">
      <div class="warehouses-items__item" :key="w.id">
        <span v-html="w.warehouseName" />
        <v-tooltip bottom open-delay="500" v-if="w.auto_disabled">
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="14px" v-bind="attrs" v-on="on">
              mdi-alert-circle
            </v-icon>
          </template>
          <span v-html="w.auto_disabled_reason_display + '<br>' + lastSyncAt(w)" />
        </v-tooltip>
      </div>
      <div v-html="',&nbsp'" :key="w.warehouse_id" v-if="index < (warehouses.length - 1)" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductParamWarehouses',
  props: {
    value: { type: Array, required: true, default: () => [] },
  },
  computed: {
    ...mapGetters('reference', ['getWarehouseRegionName']),
    warehouses() {
      return this.value.map((w) => ({ ...w, warehouseName: this.getWarehouseRegionName(w.warehouse_id) }));
    },
  },
  methods: {
    lastSyncAt(item) {
      if (!item.last_sync_at) return '–';
      const date = new Date(Date.parse(item.last_sync_at));
      return `Дата последней синхронизации: ${date.toLocaleString('ru', { year: 'numeric', month: 'numeric', day: 'numeric' })}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.warehouses-items {
  display: flex;
  align-items: center;
  text-align: left;

  &__item {
    display: flex;
    align-items: center;
  }
}
</style>
