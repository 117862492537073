<template>
  <div class="product-params" v-if="canonicalProductId">
    <v-row class="product-params__listing-item text-caption" v-if="shortList.length > 0">
      <v-col v-text="'Дополнительные характеристики'" class="text--secondary" />
    </v-row>
    <v-row class="product-params__listing-item text-caption" v-for="(item, i) in list" :key="i">
      <v-col class="text--secondary" cols="6">{{ `${item.attribute.name}:` }}</v-col>
      <v-col cols="6">
        <span v-text="item.value[0] || '–'" />
      </v-col>
    </v-row>
    <v-row class="text-caption">
      <v-col class="d-flex justify-center align-center" cols="12">
        <v-btn class="text-capitalize" depressed small @click="showAll = !showAll" v-if="fullList.length > attributesCount">
          {{ showAll ? 'Свернуть' : 'Все характеристики' }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { getAttributesWithValues } from '@/helpers/productAttributes';

export default {
  props: {
    canonicalProductId: { type: String, required: true },
  },
  data() {
    return {
      fullList: [],
      showAll: false,
      attributesCount: 12,
    };
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    ...mapGetters('attribute_groups', ['attributes']),
    attributeValues() {
      return this.product?.attribute_values;
    },
    attributeGroupId() {
      return this.product?.attribute_group?.id;
    },
    shortList() {
      return this.fullList.slice(0, 12);
    },
    list() {
      if (this.showAll) return this.fullList;
      return this.shortList;
    },
  },
  watch: {
    canonicalProductId: {
      immediate: true,
      handler() {
        try {
          if (this.attributeGroupId) this.FETCH_ATTRIBUTE_GROUP(this.attributeGroupId);
          this.fillList();
        } catch (e) {
          if (!e.isAxiosError) throw e;
        }
      },
    },
  },
  methods: {
    ...mapActions('attribute_groups', ['FETCH_ATTRIBUTE_GROUP']),
    fillList() {
      this.fullList = getAttributesWithValues(this.attributes, this.attributeValues);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-params {

  &__listing {
    display: flex;
    margin-top: -8px;
    margin-left: -8px;

    &-item {
      border-bottom: solid 1px;
      border-bottom-color: hsla(0, 0%, 92%, 1);
    }
  }

  &--small {
    font-size: 12px;
  }
}
</style>
