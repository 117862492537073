export default {
  show(event, item, imageProperty) {
    if (!item[imageProperty]) return;
    const popover = document.createElement('div');
    popover.id = 'popover';
    popover.style.cssText = `
      position: absolute;
      z-index: 100;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
      top: ${event.pageY - 180}px;
    `;
    popover.innerHTML = `
      <img width="140px" max-height="280px" src="${item[imageProperty]}" >
    `;
    event.target.appendChild(popover);
  },
  hide() {
    const popover = document.querySelector('#popover');
    if (popover) popover.remove();
  },
};
