<template>
  <div class="text-left text-caption text-no-wrap d-flex justify-space-between align-center">
    <div v-html="prices" />
    <div class="d-flex" v-if="prices">
      <v-icon class="mx-2" x-small>
        mdi-close
      </v-icon>
      <UnitFactor class="text--secondary factor-text" :product="item" @affected="onAffect" @focus="focused = true" @blur="focused = false" v-if="over || focused" />
      <div class="text--secondary factor-text" v-text="itemUnitFactor" v-else />
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/helpers/formatters';
import UnitFactor from '@/components/common/Product/UnitFactor';

export default {
  components: {
    UnitFactor,
  },
  props: {
    item: { type: Object, required: true },
    over: { type: Boolean, default: false },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    itemUnitFactor() {
      return parseFloat(this.item.unit_factor).toFixed(3);
    },
    prices() {
      return formatPrice(this.item.warehouse_items);
    },
  },
  methods: {
    onAffect(item) {
      this.$emit('update', item);
    },
  },
};
</script>

<style scoped>
.factor-text {
  width: 56px;
  text-align: center;
}
</style>
