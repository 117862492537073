<template>
  <v-row>
    <v-col class="mr-4" cols="6">
      <v-row v-for="(row, i) in rows" :key="i" class="product-params__listing-item text-caption">
        <v-col class="text--secondary" cols="6">{{ row.label }}</v-col>
        <v-col cols="6">
          <component :is="row.component" :value="row.value" />
        </v-col>
      </v-row>
      <v-row class="product-params__listing-item text-caption">
        <v-col class="text--secondary" cols="6">Коэффициент пересчёта в единицы эталона</v-col>
        <v-col cols="6">
          <UnitFactor :product="product" @affected="updateProduct" />
        </v-col>
      </v-row>
      <PricePerUnitFactor class="product-params__listing-item" :product="product" v-if="showPerUnitPrice" />
      <PricePerUnitFactor class="product-params__listing-item" :product="product" price-field="retail_price_per_unit_factor" title="РРЦ" v-if="showPerUnitPrice" />
    </v-col>
    <v-col auto>
      <ProductCanonicalParams :canonical-product-id="product.gmid" v-if="product.gmid" />
    </v-col>
  </v-row>
</template>

<script>

import { mapState } from 'vuex';
import { formatPrice } from '@/helpers/formatters';

import ProductCanonicalParams from '@/components/ProductCard/ProductCanonicalParams';
import PricePerUnitFactor from '@/components/common/Product/PricePerUnitFactor';
import UnitFactor from '@/components/common/Product/UnitFactor';
import ProductParamValue from '@/components/ProductCard/ProductParamValue';
import ProductParamWarehouses from '@/components/ProductCard/ProductParamWarehouses';

export default {
  components: {
    ProductCanonicalParams,
    PricePerUnitFactor,
    UnitFactor,
    ProductParamValue,
    ProductParamWarehouses,
  },
  computed: {
    ...mapState('inventory_items', ['product']),
    rows() {
      return [
        { label: 'Артикул поставщика:', component: ProductParamValue, value: this.product.sku },
        { label: 'Бренд:', component: ProductParamValue, value: this.product.vendor?.name ?? '–' },
        { label: 'EAN:', component: ProductParamValue, value: this.product.ean || '–' },
        { label: 'Единица измерения:', component: ProductParamValue, value: this.product.unit_localized },
        { label: 'Вес:', component: ProductParamValue, value: this.product.weight || '–' },
        { label: 'Высота:', component: ProductParamValue, value: this.product.height || '–' },
        { label: 'Длина:', component: ProductParamValue, value: this.product.length || '–' },
        { label: 'Ширина:', component: ProductParamValue, value: this.product.width || '–' },
        { label: 'Склад:', component: ProductParamWarehouses, value: this.product.warehouse_items },
        { label: 'Остатки на складе:', component: ProductParamValue, value: this.product.warehouse_total_stock },
        { label: `Цена/${this.product.unit_localized}`, component: ProductParamValue, value: this.getPrices },
        { label: `РРЦ/${this.product.unit_localized}`, component: ProductParamValue, value: this.getRetailPrices },
      ];
    },
    getPrices() {
      return formatPrice(this.product.warehouse_items) || '–';
    },
    getRetailPrices() {
      return formatPrice(this.product.warehouse_items, 'retail_price') || '–';
    },
    showPerUnitPrice() {
      return parseFloat(this.product.unit_factor, 10) !== 1;
    },
  },
  methods: {
    updateProduct(product) {
      this.$store.commit('inventory_items/SET_PRODUCT', product);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-params {

  &__listing {
    display: flex;
    margin-top: -8px;
    margin-left: -8px;

    &-item {
      border-bottom: solid 1px;
      border-bottom-color: hsla(0, 0%, 92%, 1);
    }
  }

}

</style>
